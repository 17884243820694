import * as React from "react";
import { graphql } from "gatsby";
//import { Link } from "gatsby";
//import { GatsbyImage } from "gatsby-plugin-image";
//import { Container } from 'react-bootstrap';
//import { Row } from 'react-bootstrap';
//import { Col } from 'react-bootstrap';

import WorkDetail from "../../components/parts/work-detail";
import WorkDetailHero from "../../components/parts/work-detail-hero";
import IframeYouTube from "../../components/parts/iframe-youtube";
//import IframeVimeo from "../../components/parts/iframe-vimeo";
import WorkDetailImageGallery from "../../components/parts/work-detail-image-gallery";
import WorkDetailImages from "../../components/parts/work-detail-images";
//import WorkDetailMiddleSection from "../../components/parts/work-detail-middle-section";
//import WorkDetailCredit from "../../components/parts/work-detail-credit";

import "../../styles/global/base.scss";
import "../../styles/global/_list.scss";
import "../../styles/work-detail.scss";

const WorkDetailPage = ({ location, data }) => {

  const imageNodes = data.images.edges;
  const ogpImage = (!data.ogpImage) ? null : data.ogpImage.publicURL;

  const HeroContent = () => {
    return (
      <WorkDetailHero className="">
        <IframeYouTube width="1280" height="720" id="D9lZOhTU_LQ" />
      </WorkDetailHero>
    );
  };

  const OutlineContent = () => {
    return (
      <p>
        2018年に開催された山口ゆめ花博の中で、明治維新150年記念施設として設置された「維新体験館」のVRコンテンツを制作。吉田松陰が指導にあたった松下村塾や高杉晋作の功山寺での挙兵、伊藤博文らのイギリスへの留学の3シーンで構成されている。それぞれのシーンで吉田松陰、高杉晋作、伊藤博文らがVR上で登場し、維新の時代の「志」を語る。
      </p>
    );
  };

  const summaryData = {
    scopes: ['Produce', 'Direction', 'Modeling', 'Programming', 'Video Production'],
    awards: [],
    outline: OutlineContent
  };

  return (
    <WorkDetail
      location={location}
      summary={summaryData}
      heroContent={HeroContent}
      ogpImage={ogpImage}
    >
      <WorkDetailImageGallery>
        <WorkDetailImages images={imageNodes.slice(0, 2)} column={1} />
        <WorkDetailImages images={imageNodes.slice(2, 5)} column={3} />
      </WorkDetailImageGallery>
    </WorkDetail>
  );
};

export default WorkDetailPage;

export const query = graphql`
  query {
    images: allFile(
      filter: {relativeDirectory: {eq: "works/meiji_vr"}}
      sort: {fields: name, order: ASC}
    ) {
      edges {
        node {
          id
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED, width: 1920)
            id
          }
        }
      }
    }
    ogpImage: file(relativePath: {glob: "works/ogp/meiji_vr.*"}) {
      publicURL
    }
  }
`;
